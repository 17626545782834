/* Global css */
body {
    background: #f7f7f7;
    padding: 0px;
    margin: 0px;
  }
  
  * {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
  }
  
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    width: 100%;
    cursor: pointer;
  }
.main-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0px;
  /* height: 600px; */
  background: #fff;
}
.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    background: #19274c;
    color:#fff
}
.header-right {
  background: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0px 5px;
  height: 22px;
  top: 3px;
  position: relative;
  border-radius: 3px;
}
.logo_header{
  height: 45px;
  position: absolute;
  width: 100px;
  top: 0px;

}
.logout-img {
  height: 12px;
}
.logout-text {
  color: #000;
  font-size: 12px;
  padding: 5px;
}
.ham_header{
  height: 25px;
  width: 25px;
}
.trip-date-nav-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;

    /* background: blue; */
}
.red {
  background: #fff;
}
.nav {
  font-size: 12px;
  margin-right: 2px;
}
.nav.active {
  color: #00bddc;
}
.trip-summary-wrap {
    padding: 25px;
}
.trip-summary-right {
  display: flex;
  justify-content: flex-end;
  width: 68%;
}
.date-from-wrapper {
  position: relative;
}
.date-to-wrapper {
  position: relative;
}
.react-datepicker-popper {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(-34px, 21px, 0px) !important;
}

.fa-search:before {
  content: "\f002";
  color: #ffff;
}
.search-btn {
  width: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #27d5f6;
  margin: 0 2px;
}
span.from-text {
  font-size: 12px;
  padding: 0 3px;
  margin: 0 4px;
  border-radius: 2px;
}
.export-btn {
  background: #fff;
  border-radius: 2px;
  border: 1px solid #27d5f6;
  margin: 0 3px;
  font-size: 12px;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0 3px;
}
span.from-text {
  font-size: 12px;
  padding: 0 3px;
}
span.to-text {
  font-size: 12px;
  padding: 0 3px;
}
input {
  border-radius: 2px;
  border: 1px solid lightgray;
  font-size: 12px;
  padding: 4px;
}

.strip_card_container {
  width: 60%;
  display: flex;
  flex-flow: wrap;
  margin: 0 19px;
}
i.fa.fa-clock-o {
  color: #fff;
  padding: 0 4px;
}
.strip_card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  width: 30.33%;
  color: #fff;
  font-size: 12px;
  margin: 3px auto;
}
.strip-text {
  margin: 0 4px;
}
.skyblue{
  background: #27d5f6;
}
.yellow{
  background: #c2993b;
}
.blue{
  background: #005a93;
}
.green{
  background: #00a74b;
}
.pink{
  background: #962297;
}
.brown{
  background: #6a5718;
}
.black {
  background: #000;
}
.strip_card_img_wrap {
  display: flex;
}
.strip_card.black {
  padding: 0 7px;
}



/* accordian */

    .panel-group .panel {
        border-radius: 0;
        box-shadow: none;
        border-color: #EEEEEE;
    }

    .panel-default > .panel-heading {
        padding: 0;
        border-radius: 0;
        color: #212121;
        background-color: #FAFAFA;
        border-color: #EEEEEE;
    }

    .panel-title {
        font-size: 14px;
    }

    .panel-title > a {
        display: block;
        padding: 15px;
        text-decoration: none;
    }

    .more-less {
        float: right;
        color: #212121;
    }

    .panel-default > .panel-heading + .panel-collapse > .panel-body {
        border-top-color: #EEEEEE;
    }

    .clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical>.btn-group:before, .btn-group-vertical>.btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after, .modal-footer:before, .modal-footer:after {
      display: table;
      content: none !important ;
  }

  .panel-title > a {
    display: block;
    padding: 6px 15px;
    text-decoration: none;
    background: #19274c;
}
th, td {
  font-size: 12px;
}
.table-bordered>thead>tr>th, .table-bordered>thead>tr>td {
  background: #27d5f6;
  border-bottom-width: 2px;
}
.glyphicon-plus:before {
  content: "\2b";
  color: white;
}
.glyphicon-minus:before {
  color: white;
  content: "\2212";
}
.container {
  width: 95%;
  margin: 0 24px;
  padding: 0;
}
span.table_head_text {
  color: #fff;
  font-size: 10px;
}
.movement-report {
  background: #27d5f6;
  margin: 0 3px;
  padding: 4px 5px;
  border-radius: 2px;
  color: #fff;
  font-size: 10px;
}
.movement-report {
  padding: 7px 5px;
  width: 50%;
  background: #27d5f6;
  margin: 0 3px;
  border-radius: 2px;
  font-size: 10px;
}
.wrapper_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrap_table_main_head {
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.total_km_expe_wrap {
  margin-right: 14px;
}
span.text_km {
  padding: 0 10px;
  color: #b2b145;
}
span.text_expense {
  color: #53c8de;
}
.panel-collapse {
  overflow: auto;
}
@media screen and (max-width: 700px) {
  .trip-date-nav-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}
.react-datepicker-popper {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(-34px, 21px, 0px) !important;
}
.search-btn {
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #27d5f6;
  margin: 4px 3px;
  padding: 3px 0px;
}
.export-btn {
  background: #fff;
  border-radius: 2px;
  border: 1px solid #27d5f6;
  margin: 0 3px;
  font-size: 12px;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0 3px;
  width: 52px;
}
.trip-summary-right {
  display: flex;
  justify-content: flex-end;
  width: 68%;
  flex-direction: column;
}
}
